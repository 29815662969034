import React, { useContext, useState } from 'react';
import { Button, Form } from "react-bootstrap";
import { LangContext } from 'src/Contexts/Lang';
import StudyService from 'src/Services/StudyServices';
import BulkLoadBuilder, { EnvironmentT } from 'src/utils/BulkLoader/BulkLoadBuilder';
import BulkLoadTableCsv from './BulkLoadTableCsv';

interface TabLoadFromProcesamientoI {
    handleLaunchRequest: () => void
}
export default function TabLoadFromProcesamiento(props: TabLoadFromProcesamientoI) {
    const { dispatch: { translate } } = useContext(LangContext);
    const StudyS = new StudyService();
    const [textareaValue, setTextareaValue] = useState<string>('');
    const [transformedData, settransformedData] = useState<string>('');
    const [wrongEnvironmetData, setwrongEnvironmetData] = useState<string>('');
    const [error, seterror] = useState<string>('');
    const api_url = String(process.env.REACT_APP_API_URL)
    console.log(api_url)
    // let environment: EnvironmentT = api_url.includes("192.168.210.85") || api_url.includes("bplatform.bitgenia") ? "PROD" : "PREPROD"
    let environment: EnvironmentT = api_url.includes("192.168.210.77") || api_url.includes("apps3") ? "PREPROD" : "PROD"
    console.log(environment)
    const handleTextareaChange = (e: any) => {
        setTextareaValue(e.target.value);
    };
    const handleTransformTextAreaData = () => {
        try {
            if (error !== '') seterror('')
            if (wrongEnvironmetData !== '') setwrongEnvironmetData('')
            const result: { successData: string, wrongEnvironmetData: string } = (new BulkLoadBuilder(textareaValue, environment)).build()
            settransformedData(result.successData);
            setwrongEnvironmetData(result.wrongEnvironmetData);
        } catch (error: any) {
            seterror(String(error.message))
        }
    }
    const handleUploadTransformedData = () => {
        const blob = new Blob([transformedData], { type: 'text/csv;charset=utf-8;' });
        const file = new File([blob], 'data.csv', { type: 'text/csv' });
        const formData = new FormData();
        formData.append('file', file);
        StudyS.upload_batch_cases(formData).then((res: any) => {
            if (res.status === 200) {
                props.handleLaunchRequest();
                setTextareaValue('');
                settransformedData('');
                setwrongEnvironmetData('');                
                // props.handleCloseBulkLoadModal();
            } else {
                console.log("error...")
            }
        })
    }
    const thereIsTransformedData = (transformedData.split('\n').length - 2) > 0;
    return (
        <div style={{ padding: "10px 0 0 0" }}>
            <Form>
                <Form.Group controlId="exampleForm.ControlTextarea1">
                    <div>
                        <Form.Label style={{ verticalAlign: "sub", marginBottom: 0, marginTop: 10 }}>
                            Inserte hoja de procesamiento (Procesamiento - LOGISTICA INTEGRAL) para que sea transformada en tabla de carga
                        </Form.Label>
                        <Button style={{ float: "right", margin: 5 }}
                            variant={textareaValue.length < 30 ? "outline-primary" : "primary"}
                            disabled={textareaValue.length < 30}
                            onClick={(e: any) => handleTransformTextAreaData()}>
                            {translate("domain.adaptSheet")}
                        </Button>
                    </div>
                    <Form.Control as="textarea"
                        rows={6}
                        style={{ fontSize: "11px", marginTop: 20 }}
                        value={textareaValue}
                        onChange={handleTextareaChange}
                    />
                </Form.Group>
            </Form>
            {error !== "" && <div>
                <p style={{ color: "red" }}>{error}</p>
            </div>
            }
            {wrongEnvironmetData !== "" && <div>
                <label style={{ color: "orange", fontWeight: "bold" }}>{translate("common.warning").toUpperCase()}!</label>
                <p style={{ color: "orange" }}>{translate("common.theNextSampleCodesAreNotInTheCorrectEnvironment")}:&nbsp;<strong>{wrongEnvironmetData}</strong></p>
            </div>}
            {
                thereIsTransformedData && <div>
                    <BulkLoadTableCsv csvData={transformedData} />
                    <Button style={{ float: "right", margin: 5 }}
                        // variant={textareaValue.length < 30 ? "outline-primary" : "primary"}
                        // disabled={textareaValue.length < 30}
                        onClick={(e: any) => handleUploadTransformedData()}>
                        {translate("common.uploadBatch")}
                    </Button>
                </div>
            }
        </div>
    )
}